<template>
  <div id="app" >
     <van-nav-bar
        left-text="返回"
        left-arrow
        title="企业实名认证"
        @click-left="$router.go(-1)"/>
    <van-form @submit="onSubmit">
      <van-cell-group>
        <label for="">请确认您的身份信息：</label>
      </van-cell-group>
      <div style="margin: 20px 0px">
        <van-cell-group>
          <van-field
            v-model="form.legalName"
            label="法人姓名"
            maxlength='20'
            :rules="[{ validator:NameValid, message: '您的法人姓名格式错误' }]"
            placeholder="法人姓名"/>
          <van-field
            v-model="form.legalPhone"
            label="法人手机号码"
            maxlength='11'
            :rules="[{ validator:PhoneValid, message: '您的法人手机号码格式错误' }]"
            placeholder="法人手机号码"/>
          <van-field
            v-model="form.legalIds"
            label="法人证件号"
            :rules="[{ required: true, message: '请填写法人证件号' }]"
            placeholder="法人证件号"/>
          <van-field
            v-model="form.companyName"
            label="企业名称"
            :rules="[{ required: true, message: '请输入企业名称' }]"
            placeholder="企业名称，如有括号，用中文格式（）"/>
          <van-field
            v-model="form.accountNo"
            label="企业对公账户"
            :rules="[{ required: true, message: '请输入企业对公账户' }]"
            placeholder="企业对公账户，支持数字和“-”字符"/>
          <van-field
            v-model="form.businessLicense"
            label="营业执照号"
            :rules="[{ required: true,message: '请输入营业执照号' }]"
            placeholder="营业执照号"/>
          <van-field
            v-model="form.parentBankName"
            label="开户银行名称"
            :rules="[{ required: true, message: '请输入开户银行名称' }]"
            placeholder="开户银行名称,如：中国工商银行"/>
          <van-field
            v-model="form.bankName"
            label="开户行支行名称"
            :rules="[{ required: true, message: '请输入开户行支行名称' }]"
            placeholder="如：“中国工商银行股份有限公司北京樱桃园支行”"/>
          <van-field
            v-model="form.unionBank"
            label="支付行号"
            :rules="[{ required: true, message: '请输入支付行号' }]"
            placeholder="12 位数字"/>
        </van-cell-group>
      </div>
      <van-cell-group>
        <van-button round size="large" icon="user-o" type="info" block>身份验证</van-button>
      </van-cell-group>
    </van-form>
  </div>
</template>

<script>
import { Button, Cell, CellGroup, Field, Form,NavBar,ActionSheet } from "vant";
import {SetCompanyInfo} from '../../api/common'
import {allinpayUser} from '../../store/allinpay'
// import {providerInfo} from '../../store/provider'
export default {
  components: {
    [Button.name]: Button,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Field.name]: Field,
    [Form.name]: Form,
    [NavBar.name]: NavBar,
    [ActionSheet.name]: ActionSheet,
  },
  data(){
      return {
          indexUrl:'',
          show:false,
          actions:[],
          form: {
            companyName: "",
            legalName: "",
            legalPhone: "",
            accountNo: "",
            legalIds: "",
            businessLicense: "",
            parentBankName: "",
            bankName: "",
            unionBank: "",
          },
          isFirst:false,
          user:""
      };
  },
  methods:{
    onSubmit(){
      var body = {
        userId:this.user.userId,
        userSource:this.user.userSource||2,
        source:2,
        companyBasicInfo:this.form
      }
      this.$toast.loading({duration: 0,forbidClick: true,message: '企业认证中',});
      SetCompanyInfo(body).then(res=>{
        this.$toast({type:res.status?'success':'fail',message:res.message})
        if(res.status){
          this.$dialog.alert({
              title: '提示',
              message: '企业会员审核成功！点击确定进行下一步骤',
              theme: 'round-button',
          }).then(() => {
            if(this.user.newUser){
              this.$router.push({path:'/bank/bindPhone',query:{first:true}});
            }else{
              this.$router.go(-1);
            }
          });
        }
      });
    },
    IdentityCodeValid(code) { 
        var city={11:"北京",12:"天津",13:"河北",14:"山西",15:"内蒙古",21:"辽宁",22:"吉林",23:"黑龙江 ",31:"上海",32:"江苏",33:"浙江",34:"安徽",35:"福建",36:"江西",37:"山东",41:"河南",42:"湖北 ",43:"湖南",44:"广东",45:"广西",46:"海南",50:"重庆",51:"四川",52:"贵州",53:"云南",54:"西藏 ",61:"陕西",62:"甘肃",63:"青海",64:"宁夏",65:"新疆",71:"台湾",81:"香港",82:"澳门",91:"国外 "};
        var pass= true;
        if(!code || !/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i.test(code)){
            pass = false;
        }else if(!city[code.substr(0,2)]){
            pass = false;
        }else{
            //18位身份证需要验证最后一位校验位
            if(code.length == 18){
                code = code.split('');
                //∑(ai×Wi)(mod 11)
                //加权因子
                var factor = [ 7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2 ];
                //校验位
                var parity = [ 1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2 ];
                var sum = 0;
                var ai = 0;
                var wi = 0;
                for (var i = 0; i < 17; i++)
                {
                    ai = code[i];
                    wi = factor[i];
                    sum += ai * wi;
                }
                if(parity[sum % 11] != code[17]){
                    pass =false;
                }
            }
        }
        return pass;
    },
    NameValid(val){
        if(!val){
            return false;
        }else if(val.length>10){
            return false;
        }else{
            return true;
        }
    },
    PhoneValid(val){
        if(!val){
            return false;
        }else{
          return this.$util.checkPhone(val);
        }
    }
  },
  mounted(){
  },
  created(){
    this.user = allinpayUser.get();
    this.isFirst = !!this.$route.query.first;
  }
};
</script>

<style>
</style>